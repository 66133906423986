import _d3Array from "d3-array";
var exports = {};

// https://d3js.org/d3-contour/ v1.3.2 Copyright 2018 Mike Bostock
(function (global, factory) {
  factory(exports, _d3Array);
})(exports, function (exports, d3Array) {
  'use strict';

  var array = Array.prototype;
  var slice = array.slice;

  function ascending(a, b) {
    return a - b;
  }

  function area(ring) {
    var i = 0,
        n = ring.length,
        area = ring[n - 1][1] * ring[0][0] - ring[n - 1][0] * ring[0][1];

    while (++i < n) area += ring[i - 1][1] * ring[i][0] - ring[i - 1][0] * ring[i][1];

    return area;
  }

  function constant(x) {
    return function () {
      return x;
    };
  }

  function contains(ring, hole) {
    var i = -1,
        n = hole.length,
        c;

    while (++i < n) if (c = ringContains(ring, hole[i])) return c;

    return 0;
  }

  function ringContains(ring, point) {
    var x = point[0],
        y = point[1],
        contains = -1;

    for (var i = 0, n = ring.length, j = n - 1; i < n; j = i++) {
      var pi = ring[i],
          xi = pi[0],
          yi = pi[1],
          pj = ring[j],
          xj = pj[0],
          yj = pj[1];
      if (segmentContains(pi, pj, point)) return 0;
      if (yi > y !== yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi) contains = -contains;
    }

    return contains;
  }

  function segmentContains(a, b, c) {
    var i;
    return collinear(a, b, c) && within(a[i = +(a[0] === b[0])], c[i], b[i]);
  }

  function collinear(a, b, c) {
    return (b[0] - a[0]) * (c[1] - a[1]) === (c[0] - a[0]) * (b[1] - a[1]);
  }

  function within(p, q, r) {
    return p <= q && q <= r || r <= q && q <= p;
  }

  function noop() {}

  var cases = [[], [[[1, 1.5], [0.5, 1]]], [[[1.5, 1], [1, 1.5]]], [[[1.5, 1], [0.5, 1]]], [[[1, 0.5], [1.5, 1]]], [[[1, 1.5], [0.5, 1]], [[1, 0.5], [1.5, 1]]], [[[1, 0.5], [1, 1.5]]], [[[1, 0.5], [0.5, 1]]], [[[0.5, 1], [1, 0.5]]], [[[1, 1.5], [1, 0.5]]], [[[0.5, 1], [1, 0.5]], [[1.5, 1], [1, 1.5]]], [[[1.5, 1], [1, 0.5]]], [[[0.5, 1], [1.5, 1]]], [[[1, 1.5], [1.5, 1]]], [[[0.5, 1], [1, 1.5]]], []];

  function contours() {
    var dx = 1,
        dy = 1,
        threshold = d3Array.thresholdSturges,
        smooth = smoothLinear;

    function contours(values) {
      var tz = threshold(values); // Convert number of thresholds into uniform thresholds.

      if (!Array.isArray(tz)) {
        var domain = d3Array.extent(values),
            start = domain[0],
            stop = domain[1];
        tz = d3Array.tickStep(start, stop, tz);
        tz = d3Array.range(Math.floor(start / tz) * tz, Math.floor(stop / tz) * tz, tz);
      } else {
        tz = tz.slice().sort(ascending);
      }

      return tz.map(function (value) {
        return contour(values, value);
      });
    } // Accumulate, smooth contour rings, assign holes to exterior rings.
    // Based on https://github.com/mbostock/shapefile/blob/v0.6.2/shp/polygon.js


    function contour(values, value) {
      var polygons = [],
          holes = [];
      isorings(values, value, function (ring) {
        smooth(ring, values, value);
        if (area(ring) > 0) polygons.push([ring]);else holes.push(ring);
      });
      holes.forEach(function (hole) {
        for (var i = 0, n = polygons.length, polygon; i < n; ++i) {
          if (contains((polygon = polygons[i])[0], hole) !== -1) {
            polygon.push(hole);
            return;
          }
        }
      });
      return {
        type: "MultiPolygon",
        value: value,
        coordinates: polygons
      };
    } // Marching squares with isolines stitched into rings.
    // Based on https://github.com/topojson/topojson-client/blob/v3.0.0/src/stitch.js


    function isorings(values, value, callback) {
      var fragmentByStart = new Array(),
          fragmentByEnd = new Array(),
          x,
          y,
          t0,
          t1,
          t2,
          t3; // Special case for the first row (y = -1, t2 = t3 = 0).

      x = y = -1;
      t1 = values[0] >= value;
      cases[t1 << 1].forEach(stitch);

      while (++x < dx - 1) {
        t0 = t1, t1 = values[x + 1] >= value;
        cases[t0 | t1 << 1].forEach(stitch);
      }

      cases[t1 << 0].forEach(stitch); // General case for the intermediate rows.

      while (++y < dy - 1) {
        x = -1;
        t1 = values[y * dx + dx] >= value;
        t2 = values[y * dx] >= value;
        cases[t1 << 1 | t2 << 2].forEach(stitch);

        while (++x < dx - 1) {
          t0 = t1, t1 = values[y * dx + dx + x + 1] >= value;
          t3 = t2, t2 = values[y * dx + x + 1] >= value;
          cases[t0 | t1 << 1 | t2 << 2 | t3 << 3].forEach(stitch);
        }

        cases[t1 | t2 << 3].forEach(stitch);
      } // Special case for the last row (y = dy - 1, t0 = t1 = 0).


      x = -1;
      t2 = values[y * dx] >= value;
      cases[t2 << 2].forEach(stitch);

      while (++x < dx - 1) {
        t3 = t2, t2 = values[y * dx + x + 1] >= value;
        cases[t2 << 2 | t3 << 3].forEach(stitch);
      }

      cases[t2 << 3].forEach(stitch);

      function stitch(line) {
        var start = [line[0][0] + x, line[0][1] + y],
            end = [line[1][0] + x, line[1][1] + y],
            startIndex = index(start),
            endIndex = index(end),
            f,
            g;

        if (f = fragmentByEnd[startIndex]) {
          if (g = fragmentByStart[endIndex]) {
            delete fragmentByEnd[f.end];
            delete fragmentByStart[g.start];

            if (f === g) {
              f.ring.push(end);
              callback(f.ring);
            } else {
              fragmentByStart[f.start] = fragmentByEnd[g.end] = {
                start: f.start,
                end: g.end,
                ring: f.ring.concat(g.ring)
              };
            }
          } else {
            delete fragmentByEnd[f.end];
            f.ring.push(end);
            fragmentByEnd[f.end = endIndex] = f;
          }
        } else if (f = fragmentByStart[endIndex]) {
          if (g = fragmentByEnd[startIndex]) {
            delete fragmentByStart[f.start];
            delete fragmentByEnd[g.end];

            if (f === g) {
              f.ring.push(end);
              callback(f.ring);
            } else {
              fragmentByStart[g.start] = fragmentByEnd[f.end] = {
                start: g.start,
                end: f.end,
                ring: g.ring.concat(f.ring)
              };
            }
          } else {
            delete fragmentByStart[f.start];
            f.ring.unshift(start);
            fragmentByStart[f.start = startIndex] = f;
          }
        } else {
          fragmentByStart[startIndex] = fragmentByEnd[endIndex] = {
            start: startIndex,
            end: endIndex,
            ring: [start, end]
          };
        }
      }
    }

    function index(point) {
      return point[0] * 2 + point[1] * (dx + 1) * 4;
    }

    function smoothLinear(ring, values, value) {
      ring.forEach(function (point) {
        var x = point[0],
            y = point[1],
            xt = x | 0,
            yt = y | 0,
            v0,
            v1 = values[yt * dx + xt];

        if (x > 0 && x < dx && xt === x) {
          v0 = values[yt * dx + xt - 1];
          point[0] = x + (value - v0) / (v1 - v0) - 0.5;
        }

        if (y > 0 && y < dy && yt === y) {
          v0 = values[(yt - 1) * dx + xt];
          point[1] = y + (value - v0) / (v1 - v0) - 0.5;
        }
      });
    }

    contours.contour = contour;

    contours.size = function (_) {
      if (!arguments.length) return [dx, dy];

      var _0 = Math.ceil(_[0]),
          _1 = Math.ceil(_[1]);

      if (!(_0 > 0) || !(_1 > 0)) throw new Error("invalid size");
      return dx = _0, dy = _1, contours;
    };

    contours.thresholds = function (_) {
      return arguments.length ? (threshold = typeof _ === "function" ? _ : Array.isArray(_) ? constant(slice.call(_)) : constant(_), contours) : threshold;
    };

    contours.smooth = function (_) {
      return arguments.length ? (smooth = _ ? smoothLinear : noop, contours) : smooth === smoothLinear;
    };

    return contours;
  } // TODO Optimize edge cases.
  // TODO Optimize index calculation.
  // TODO Optimize arguments.


  function blurX(source, target, r) {
    var n = source.width,
        m = source.height,
        w = (r << 1) + 1;

    for (var j = 0; j < m; ++j) {
      for (var i = 0, sr = 0; i < n + r; ++i) {
        if (i < n) {
          sr += source.data[i + j * n];
        }

        if (i >= r) {
          if (i >= w) {
            sr -= source.data[i - w + j * n];
          }

          target.data[i - r + j * n] = sr / Math.min(i + 1, n - 1 + w - i, w);
        }
      }
    }
  } // TODO Optimize edge cases.
  // TODO Optimize index calculation.
  // TODO Optimize arguments.


  function blurY(source, target, r) {
    var n = source.width,
        m = source.height,
        w = (r << 1) + 1;

    for (var i = 0; i < n; ++i) {
      for (var j = 0, sr = 0; j < m + r; ++j) {
        if (j < m) {
          sr += source.data[i + j * n];
        }

        if (j >= r) {
          if (j >= w) {
            sr -= source.data[i + (j - w) * n];
          }

          target.data[i + (j - r) * n] = sr / Math.min(j + 1, m - 1 + w - j, w);
        }
      }
    }
  }

  function defaultX(d) {
    return d[0];
  }

  function defaultY(d) {
    return d[1];
  }

  function defaultWeight() {
    return 1;
  }

  function density() {
    var x = defaultX,
        y = defaultY,
        weight = defaultWeight,
        dx = 960,
        dy = 500,
        r = 20,
        // blur radius
    k = 2,
        // log2(grid cell size)
    o = r * 3,
        // grid offset, to pad for blur
    n = dx + o * 2 >> k,
        // grid width
    m = dy + o * 2 >> k,
        // grid height
    threshold = constant(20);

    function density(data) {
      var values0 = new Float32Array(n * m),
          values1 = new Float32Array(n * m);
      data.forEach(function (d, i, data) {
        var xi = +x(d, i, data) + o >> k,
            yi = +y(d, i, data) + o >> k,
            wi = +weight(d, i, data);

        if (xi >= 0 && xi < n && yi >= 0 && yi < m) {
          values0[xi + yi * n] += wi;
        }
      }); // TODO Optimize.

      blurX({
        width: n,
        height: m,
        data: values0
      }, {
        width: n,
        height: m,
        data: values1
      }, r >> k);
      blurY({
        width: n,
        height: m,
        data: values1
      }, {
        width: n,
        height: m,
        data: values0
      }, r >> k);
      blurX({
        width: n,
        height: m,
        data: values0
      }, {
        width: n,
        height: m,
        data: values1
      }, r >> k);
      blurY({
        width: n,
        height: m,
        data: values1
      }, {
        width: n,
        height: m,
        data: values0
      }, r >> k);
      blurX({
        width: n,
        height: m,
        data: values0
      }, {
        width: n,
        height: m,
        data: values1
      }, r >> k);
      blurY({
        width: n,
        height: m,
        data: values1
      }, {
        width: n,
        height: m,
        data: values0
      }, r >> k);
      var tz = threshold(values0); // Convert number of thresholds into uniform thresholds.

      if (!Array.isArray(tz)) {
        var stop = d3Array.max(values0);
        tz = d3Array.tickStep(0, stop, tz);
        tz = d3Array.range(0, Math.floor(stop / tz) * tz, tz);
        tz.shift();
      }

      return contours().thresholds(tz).size([n, m])(values0).map(transform);
    }

    function transform(geometry) {
      geometry.value *= Math.pow(2, -2 * k); // Density in points per square pixel.

      geometry.coordinates.forEach(transformPolygon);
      return geometry;
    }

    function transformPolygon(coordinates) {
      coordinates.forEach(transformRing);
    }

    function transformRing(coordinates) {
      coordinates.forEach(transformPoint);
    } // TODO Optimize.


    function transformPoint(coordinates) {
      coordinates[0] = coordinates[0] * Math.pow(2, k) - o;
      coordinates[1] = coordinates[1] * Math.pow(2, k) - o;
    }

    function resize() {
      o = r * 3;
      n = dx + o * 2 >> k;
      m = dy + o * 2 >> k;
      return density;
    }

    density.x = function (_) {
      return arguments.length ? (x = typeof _ === "function" ? _ : constant(+_), density) : x;
    };

    density.y = function (_) {
      return arguments.length ? (y = typeof _ === "function" ? _ : constant(+_), density) : y;
    };

    density.weight = function (_) {
      return arguments.length ? (weight = typeof _ === "function" ? _ : constant(+_), density) : weight;
    };

    density.size = function (_) {
      if (!arguments.length) return [dx, dy];

      var _0 = Math.ceil(_[0]),
          _1 = Math.ceil(_[1]);

      if (!(_0 >= 0) && !(_0 >= 0)) throw new Error("invalid size");
      return dx = _0, dy = _1, resize();
    };

    density.cellSize = function (_) {
      if (!arguments.length) return 1 << k;
      if (!((_ = +_) >= 1)) throw new Error("invalid cell size");
      return k = Math.floor(Math.log(_) / Math.LN2), resize();
    };

    density.thresholds = function (_) {
      return arguments.length ? (threshold = typeof _ === "function" ? _ : Array.isArray(_) ? constant(slice.call(_)) : constant(_), density) : threshold;
    };

    density.bandwidth = function (_) {
      if (!arguments.length) return Math.sqrt(r * (r + 1));
      if (!((_ = +_) >= 0)) throw new Error("invalid bandwidth");
      return r = Math.round((Math.sqrt(4 * _ * _ + 1) - 1) / 2), resize();
    };

    return density;
  }

  exports.contours = contours;
  exports.contourDensity = density;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const contours = exports.contours,
      contourDensity = exports.contourDensity,
      __esModule = exports.__esModule;